import './RecomendationsGeneral.scss';

import ContainerWYSIWYG from '../../ContainerWYSIWYG';
import Title from '../../Title';
import { reportColors } from '../../../helpers/colorPalete';
import { useLocation } from 'react-router-dom';

interface Props {
  data: {
    comment_member: string;
    comment_hr: string;
    anketa_recomendation: string | string[];
    streams_count: number;
    client_color: string;
  };
}

export const RecomendationsGeneral: React.FC<Props> = ({ data }) => {
  const location = useLocation();
  const { linksColor } = reportColors(data.client_color, data.streams_count);

  return (
    <div className='recomendationsGeneral'>
      {data.comment_member ? (
        <div className='comment'>
          <Title>Свободный комментарий для участника</Title>
          <ContainerWYSIWYG size='default' data={data.comment_member} />
        </div>
      ) : null}
      {data.comment_hr && location.pathname.indexOf('/for_hr/') !== -1 ? (
        <div className='comment'>
          <Title>Свободный комментарий для HR и руководителя</Title>
          <ContainerWYSIWYG size='default' data={data.comment_hr} />
        </div>
      ) : null}
      {data.streams_count === 1 && typeof data.anketa_recomendation === 'string' && data.anketa_recomendation !== '' && (
        <div className={`courseRecomendation ${data.comment_member || data.comment_hr ? 'mt-[200rem]' : ''} break-before-page break-inside-avoid`}>
          <Title>На основе вашего результата прохождения диагностики продуктовых навыков, рекомендуем следующие материалы:</Title>
          <ContainerWYSIWYG size='default' data={String(data.anketa_recomendation)} />
        </div>
      )}
      {data.streams_count === 2 && typeof data.anketa_recomendation === 'object' && data.anketa_recomendation[1] !== '' && (
        <div className={`courseRecomendation ${data.comment_member || data.comment_hr ? 'mt-[190rem]' : ''} break-before-page break-inside-avoid`}>
          <Title>На основе вашего результата прохождения диагностики продуктовых навыков, рекомендуем следующие материалы:</Title>
          <ContainerWYSIWYG size='default' data={String(data.anketa_recomendation[1])} />
        </div>
      )}
    </div>
  );
};
