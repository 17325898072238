import './ExpertAnketaQuestionRate.scss';

import { useState } from 'react';

import axios from 'axios';
import { Button } from '../../../components/Button/Button';
import { backendURL } from '../../../App';
import { queryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import consoleLog from '../../../helpers/consoleLog';
import Input from '../../../components/Input';
import TextareaWYSIWYG from '../../../components/TextareaWYSIWYG';
import useExpertRateCommentMutation from '../../../hooks/useExpertRateCommentMutation';

interface Props {
  anketaQestionsAnswerData: {
    id: number;
    rate?: number;
    expert_comment?: string;
  };
  questionData: {
    help_text?: boolean;
    help_text_0?: string;
    help_text_1?: string;
    help_text_2?: string;
    help_text_3?: string;
  };
  currentQuestionNumber: number;
  questionsCount: number;
  setIsAnketaQuestionRateHelpModal: () => void;
}

export const ExpertAnketaQuestionRate: React.FC<Props> = ({
  anketaQestionsAnswerData,
  currentQuestionNumber,
  questionsCount,
  setIsAnketaQuestionRateHelpModal,
  questionData,
}) => {
  const { id, rate } = anketaQestionsAnswerData;
  const { help_text, help_text_0, help_text_1, help_text_2, help_text_3 } = questionData;
  const { anketaID, anketaAnswersID } = useParams<{ anketaID: string; anketaAnswersID: string }>();
  const [expertComment, setExpertComment] = useState<string>(anketaQestionsAnswerData.expert_comment || '');
  const history = useHistory();

  const goToNextQuestion = () => {
    questionsCount === currentQuestionNumber
      ? history.push(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/rate/`)
      : history.push(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${currentQuestionNumber + 1}`);
  };
  const [mutate, { status: expertRateCommentMutationStatus }] = useExpertRateCommentMutation({
    onSuccess: () => {
      goToNextQuestion();
      queryCache.invalidateQueries(['anketaQuestionsAnswersData', anketaAnswersID]);
    },
  });

  const onRateSubmit = newRate => {
    newRate !== rate &&
      axios({
        method: 'post',
        url: `${backendURL}answer/${id}/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        data: {
          rate: newRate,
          expert_comment: expertComment || '',
        },
      })
        .then(result => {
          queryCache.invalidateQueries('anketaQuestionsAnswersData');
          consoleLog('success', 'Rate', result);
        })
        .catch(error => {
          consoleLog('error', 'Rate', error);
        });
  };

  return (
    <div className='expertAnketaQuestionRate'>
      <div className='rateTitle'>баллы за ответ</div>
      {help_text && (
        <button className='rateInstruction' type='button' onClick={setIsAnketaQuestionRateHelpModal}>
          Инструкция для оценки
        </button>
      )}
      <div className={`rateWrapper${rate === 0 ? ' seletedRate' : ''}`} onClick={() => onRateSubmit(0)}>
        <div className='indicator'></div>
        <div className='value'>0</div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: help_text_0 || 'Нет подсказки',
          }}
        ></div>
      </div>
      <div className={`rateWrapper${rate === 1 ? ' seletedRate' : ''}`} onClick={() => onRateSubmit(1)}>
        <div className='indicator'></div>
        <div className='value'>1</div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: help_text_1 || 'Нет подсказки',
          }}
        ></div>
      </div>
      <div className={`rateWrapper${rate === 2 ? ' seletedRate' : ''}`} onClick={() => onRateSubmit(2)}>
        <div className='indicator'></div>
        <div className='value'>2</div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: help_text_2 || 'Нет подсказки',
          }}
        ></div>
      </div>
      <div className={`rateWrapper${rate === 3 ? ' seletedRate' : ''}`} onClick={() => onRateSubmit(3)}>
        <div className='indicator'></div>
        <div className='value'>3</div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: help_text_3 || 'Нет подсказки',
          }}
        ></div>
      </div>

      <TextareaWYSIWYG
        modulesType='noModules'
        value={expertComment}
        onChange={value => setExpertComment(value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
        placeholderType='alwaysVisible'
        placeholder='Комментарий к оценке'
        isValid={true}
        // validationError={undefined}
        size='small'
      />

      <Button
        type='button'
        onClick={expertComment ? () => mutate({ answerID: id, expertComment, rate: rate }) : () => goToNextQuestion()}
        width='100%'
        size='large'
        color='blue'
        text='Сохранить и к следующему вопросу'
        loading={expertRateCommentMutationStatus === 'loading'}
      />
    </div>
  );
};
