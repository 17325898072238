import './AnketaExpertComment.scss';
import 'react-quill/dist/quill.snow.css';

import { useState, useEffect } from 'react';

import ReactQuill from 'react-quill';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Title } from '../Title/Title';
import { anketa_description_modal_display_toggle } from '../../actions';
import { ReactSelect } from '../../components/ReactSelect/ReactSelect';
import { Button } from '../Button/Button';
import { backendURL } from '../../App';
import consoleLog from '../../helpers/consoleLog';
import { InputError } from '../InputError/InputError';

interface AnketaExpertCommentProps {
  anketaAnswersData: any;
  anketaQestionsAnswersData: any;
}

export const AnketaExpertComment: React.FC<AnketaExpertCommentProps> = ({ anketaAnswersData, anketaQestionsAnswersData }) => {
  const history = useHistory();
  const modules = {
    toolbar: [['bold'], [{ list: 'bullet' }, { list: 'ordered' }]],
  };
  const dispatch = useDispatch();
  const { anketaID } = useParams<{ anketaID: string }>();
  const { anketaAnswersID } = useParams<{ anketaAnswersID: string }>();
  const [cheatValue, setCheatValue] = useState(-1);
  const [commentForMember, setCommentForMember] = useState('');
  const [commentForHR, setCommentForHR] = useState('');
  const [cheatValueError, setCheatValueError] = useState('');
  const [commentForMemberError, setCommentForMemberError] = useState('');
  const [commentForHRError, setCommentForHRError] = useState('');
  const [unratedAnswersError, setUnratedAnswersError] = useState('');
  const [onSaveSuccessMessage, setOnSaveSuccessMessage] = useState('');
  const [onSubmitSuccessMessage, setOnSubmitSuccessMessage] = useState('');
  const cheatOptions = [
    { value: 0, label: 'Есть подозрение, что участнику помогали' },
    { value: 1, label: 'Участник заполнил анкету самостоятельно' },
  ];

  const onReport = () => {
    window.open(`/expert/review/anketa/${anketaID}/${anketaAnswersID}/pdf/`, "_blank");
  }

  const onSave = () => {
    // * replace(/(<([^>]+)>)/gi, '') - стрипаем все html теги wysiwyg
    if (cheatValue === -1 || !commentForMember.replace(/(<([^>]+)>)/gi, '') || !commentForHR.replace(/(<([^>]+)>)/gi, '')) {
      if (cheatValue === -1) {
        setCheatValueError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCheatValueError('');
        }, 3000);
      }
      if (!commentForMember.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForMemberError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCommentForMemberError('');
        }, 3000);
      }
      if (!commentForHR.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForHRError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCommentForHRError('');
        }, 3000);
      }
    } else {
      axios({
        method: 'post',
        url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        data: {
          who_filled: cheatOptions[cheatValue].label,
          comment_member: commentForMember,
          comment_hr: commentForHR,
        },
      })
        .then(result => {
          setOnSaveSuccessMessage('Сохранено');
          setTimeout(() => {
            setOnSaveSuccessMessage('');
          }, 3000);
          consoleLog('success', 'Comment saved', result);
        })
        .catch(error => {
          consoleLog('error', 'Comment saved', error);
        });
    }
  };

  const checkQuestionsAnswersForRates = () => {
    let questionsWithoutAnswer = 0;
    anketaQestionsAnswersData.forEach(questionAnswer => {
      if (questionAnswer.rate === null) {
        questionsWithoutAnswer++;
      }
    });
    return questionsWithoutAnswer;
  };

  const onSubmit = () => {
    if (cheatValue === -1 || !commentForMember.replace(/(<([^>]+)>)/gi, '') || !commentForHR.replace(/(<([^>]+)>)/gi, '') || checkQuestionsAnswersForRates() > 0) {
      if (cheatValue === -1) {
        setCheatValueError('Поле обязательно для заполнения.');
        setTimeout(() => {
          setCheatValueError('');
        }, 3000);
      }
      if (!commentForMember.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForMemberError('Поле обязательно для заполнения.');
        setTimeout(() => {
          setCommentForMemberError('');
        }, 3000);
      }
      if (!commentForHR.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForHRError('Поле обязательно для заполнения.');
        setTimeout(() => {
          setCommentForHRError('');
        }, 3000);
      }
      if (checkQuestionsAnswersForRates() > 0) {
        setUnratedAnswersError('Есть неотрецензированные ответы участника.');
        setTimeout(() => {
          setUnratedAnswersError('');
        }, 3000);
      }
    } else {
      axios({
        method: 'post',
        url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        data: {
          who_filled: cheatOptions[cheatValue].label,
          comment_member: commentForMember,
          comment_hr: commentForHR,
          finish: true,
        },
      })
        .then(result => {
          setOnSubmitSuccessMessage('Отправлено на проверку администратору. Вы будете перенаправлены на страницу списка анкет через 3с.');
          setTimeout(() => {
            history.push('/expert/schedule/');
            setOnSubmitSuccessMessage('');
          }, 3000);
          consoleLog('success', 'Anketa submit', result);
        })
        .catch(error => {
          consoleLog('error', 'Anketa submit', error);
        });
    }
  };

  useEffect(() => {
    anketaAnswersData.comment_member && setCommentForMember(anketaAnswersData.comment_member);
    anketaAnswersData.comment_hr && setCommentForHR(anketaAnswersData.comment_hr);
    anketaAnswersData.who_filled === 'Есть подозрение, что участнику помогали' && setCheatValue(0);
    anketaAnswersData.who_filled === 'Участник заполнил анкету самостоятельно' && setCheatValue(1);
  }, [anketaAnswersData]);

  return (
    <>
      <section className='anketaExpertComment'>
        <div className='fields'>
          <Title>Ваши комментарии к анкете</Title>
          <button className='showCaseDescriptionButton' type='button' onClick={() => dispatch(anketa_description_modal_display_toggle(true))}>
            Описание кейса
          </button>
          {anketaAnswersData.who_filled ? (
            <ReactSelect
              defaultValue={{ value: cheatValue, label: anketaAnswersData.who_filled }}
              options={cheatOptions}
              onChange={setCheatValue}
              placeholder='Самостоятельно ли участник заполнял анкету?'
            />
          ) : (
            <ReactSelect options={cheatOptions} onChange={setCheatValue} placeholder='Самостоятельно ли участник заполнял анкету?' />
          )}
          <InputError>{cheatValueError}</InputError>
          <div className='fieldTitle'>Комментарий и рекомендации для участника, с которым вы общались</div>
          <ReactQuill className='quillEditor' theme='snow' value={commentForMember} onChange={setCommentForMember} modules={modules} />
          <div className='hint'>
            Этот комментарий получит участник, заполнивший анкету. Будьте тактичны и аккуратны с формулировками, делайте акценты на сильных сторонах.
          </div>
          <InputError>{commentForMemberError}</InputError>
          <div className='fieldTitle'>Свободный комментарий для HR и руководителя. Сильные/слабые стороны, общее впечатление.</div>
          <ReactQuill className='quillEditor' theme='snow' value={commentForHR} onChange={setCommentForHR} modules={modules} />
          <InputError className='withMarginTop'>{commentForHRError}</InputError>
        </div>
        <div className='submit'>
          <Button type='button' onClick={() => onReport()} width='100%' size='large' color='blue' text='Предварительный отчет участника' />
          <InputError className='success'>{onSaveSuccessMessage}</InputError>

          <Button type='button' onClick={() => onSave()} width='100%' size='large' color='blue' text='Сохранить анкету' />
          {!onSaveSuccessMessage ? (
            <InputError>{(cheatValueError || commentForMemberError || commentForHRError) && 'Есть незаполненные поля.'}</InputError>
          ) : (
            <InputError className='success'>{onSaveSuccessMessage}</InputError>
          )}

          <Button type='button' onClick={() => onSubmit()} width='100%' size='large' color='black' text='Отправить анкету на проверку' />
          <div className='hint'>После того, как вы нажмете эту кнопку, изменить комментарии и оценки будет нельзя. Рекомендуем проверить все данные перед отправкой.</div>
          {!onSubmitSuccessMessage ? (
            <InputError>
              {(cheatValueError || commentForMemberError || commentForHRError) && 'Есть незаполненные поля.'}
              {(cheatValueError || commentForMemberError || commentForHRError) && <br />}
              {unratedAnswersError ? unratedAnswersError : ''}
            </InputError>
          ) : (
            <InputError className='success'>{onSubmitSuccessMessage}</InputError>
          )}
        </div>
      </section>
    </>
  );
};
