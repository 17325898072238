import './AdminClientReport.scss';

import Button from '../../../components/Button';
import ReportCreationInitModal from '../../../components/ReportCreationInitModal';
import _ from 'lodash';
import axios from 'axios';
import { backendURL } from '../../../App';
import consoleLog from '../../../helpers/consoleLog';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export const AdminClientReport: React.FC<{}> = () => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();
  const [isReportStatusModal, setIsReportStatusModal] = useState(false);

  const createClientReportAddo = () => {
    axios({
      method: 'post',
      url: `${backendURL}reports/pdf/`,
      data: {
        type: 'client',
        id: clientID,
        stream_id: streamID,
        pdf_render_params: `wait=footer&scrollPage=true&ignoreHttpsErrors=true&emulateScreenMedia=false`,
      },
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        setIsReportStatusModal(true);
        consoleLog('success', 'Create client / stream addo', result);
      })
      .catch(error => {
        consoleLog('error', 'Create client / stream addo', error);
      });
  };

  const createClientYearReport = (year: number) => {
    axios({
      method: 'post',
      url: `${backendURL}reports/year/`,
      data: {
        client_id: clientID,
        year,
      },
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        setIsReportStatusModal(true);
        consoleLog('success', 'Create year report', result);
      })
      .catch(error => {
        consoleLog('error', 'Create year report ', error);
      });
  };

  const ReportButton = ({ year, onClick }) => (
    <Button
      type='button'
      buttonType='button'
      color='black'
      width='300rem'
      size='small'
      onClick={() => onClick(year)}
      dataCy={`createReportByClient${year}Btn`}
    >
      Отчёт по клиенту {year}г
    </Button>
  );

  const currentYear = new Date().getFullYear();
  const startYear = 2021;

  return (
    <>
      <div className='adminClientReport'>
        <Button type='button' buttonType='button' color='blue' width='300rem' size='small'
                onClick={() => createClientReportAddo()} dataCy='createReportByClientBtn'>
          {_.isNaN(Number(streamID)) ? 'Создать отчёт по клиенту' : 'Создать отчёт по потоку'}
        </Button>

        <div className='yearsReports'>
          {[...Array(currentYear - startYear + 1)].map((_, index) => {
            const year = currentYear - index;
            return (
                <ReportButton
                    key={year}
                    year={year}
                    onClick={createClientYearReport}
                />
            );
          })}
        </div>
      </div>

      {isReportStatusModal && <ReportCreationInitModal onClose={() => setIsReportStatusModal(false)}/>}
    </>
  );
};
