import './AnketaExpertComment.scss';
import 'react-quill/dist/quill.snow.css';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../Button/Button';
import { InputError } from '../InputError/InputError';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import axios from 'axios';
import { backendURL } from '../../App';
import consoleLog from '../../helpers/consoleLog';

interface AnketaExpertCommentProps {
  anketaAnswersData: any;
  clientID?: number;
}

export const AnketaExpertComment: React.FC<AnketaExpertCommentProps> = ({ anketaAnswersData, clientID }) => {
  const history = useHistory();
  const modules = {
    toolbar: [['bold'], [{ list: 'bullet' }, { list: 'ordered' }]],
  };
  const { anketaAnswersID, streamID } = useParams<{ anketaAnswersID: string; streamID: string }>();
  const [commentForMember, setCommentForMember] = useState('');
  const [commentForHR, setCommentForHR] = useState('');
  const [commentForMemberError, setCommentForMemberError] = useState('');
  const [commentForHRError, setCommentForHRError] = useState('');
  const [onReturnSuccessMessage, setOnReturnSuccessMessage] = useState('');
  const [onSubmitSuccessMessage, setOnSubmitSuccessMessage] = useState('');

  const onReturn = () => {
    if (!commentForMember.replace(/(<([^>]+)>)/gi, '') || !commentForHR.replace(/(<([^>]+)>)/gi, '')) {
      if (!commentForMember.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForMemberError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCommentForMemberError('');
        }, 3000);
      }
      if (!commentForHR.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForHRError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCommentForHRError('');
        }, 3000);
      }
    } else {
      axios({
        method: 'post',
        url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        data: {
          comment_member: commentForMember,
          comment_hr: commentForHR,
          finish: false,
        },
      })
        .then(result => {
          setOnReturnSuccessMessage(`Возвращено эксперту. ${'\n'} Вы будете перенаправлены к списку анкет через 3с.`);
          setTimeout(() => {
            setOnReturnSuccessMessage('');
            _.isNaN(Number(streamID)) ? history.push(`/admin/${clientID}/anketa_list/`) : history.push(`/admin/${streamID}/${clientID}/anketa_list/`);
          }, 3000);
          consoleLog('success', 'Returned to expert', result);
        })
        .catch(error => {
          consoleLog('error', 'Returned to expert', error);
        });
    }
  };

  const onSubmit = () => {
    if (!commentForMember.replace(/(<([^>]+)>)/gi, '') || !commentForHR.replace(/(<([^>]+)>)/gi, '')) {
      if (!commentForMember.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForMemberError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCommentForMemberError('');
        }, 3000);
      }
      if (!commentForHR.replace(/(<([^>]+)>)/gi, '')) {
        setCommentForHRError('Поле обязательно для заполнения');
        setTimeout(() => {
          setCommentForHRError('');
        }, 3000);
      }
    } else {
      axios({
        method: 'post',
        url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        data: {
          comment_member: commentForMember,
          comment_hr: commentForHR,
          admin_finish: true,
        },
      })
        .then(result => {
          setOnSubmitSuccessMessage(`Анкета закрыта. Вы будете перенаправлены к списку анкет через 3с.`);
          setTimeout(() => {
            setOnSubmitSuccessMessage('');
            _.isNaN(Number(streamID)) ? history.push(`/admin/${clientID}/anketa_list/`) : history.push(`/admin/${clientID}/${streamID}/anketa_list/`);
          }, 3000);
          consoleLog('success', 'Anketa closed', result);
        })
        .catch(error => {
          consoleLog('error', 'Anketa closed', error);
        });
    }
  };

  useEffect(() => {
    anketaAnswersData.comment_member && setCommentForMember(anketaAnswersData.comment_member);
    anketaAnswersData.comment_hr && setCommentForHR(anketaAnswersData.comment_hr);
  }, [anketaAnswersData]);

  return (
    <>
      <section className='anketaExpertComment is_admin' id='anketaExpertComment'>
        <div className='fields'>
          <div className='cheatFieldLabel'>Самостоятельно ли участник заполнял анкету? </div>
          <div className='cheatFieldValue'>Участник заполнил анкету самостоятельно</div>
          <div className='fieldTitle'>Комментарий и рекомендации для участника, с которым вы общались</div>
          <ReactQuill className='quillEditor' theme='snow' value={commentForMember} onChange={setCommentForMember} modules={modules} />
          <div className='hint'>
            Этот комментарий получит участник, заполнивший анкету. Будьте тактичны и аккуратны с формулировками, делайте акценты на сильных сторонах.
          </div>
          <div className='fieldTitle'>Свободный комментарий для HR и руководителя. Сильные/слабые стороны, общее впечатление.</div>
          <ReactQuill className='quillEditor' theme='snow' value={commentForHR} onChange={setCommentForHR} modules={modules} />
          <InputError className='withMarginTop'>{commentForHRError}</InputError>
        </div>
        <div className='submit'>
          <Button type='button' onClick={() => onReturn()} width='330rem' size='medium' color='black' text='Вернуть эксперту' />
          {!onReturnSuccessMessage ? (
            <InputError className='onReturnError'>{(commentForMemberError || commentForHRError) && 'Есть незаполненные поля.'}</InputError>
          ) : (
            <InputError className='onReturnError success'>{onReturnSuccessMessage}</InputError>
          )}

          <Button type='button' onClick={() => onSubmit()} width='330rem' size='medium' color='blue' text='Анкета готова' />
          {!onSubmitSuccessMessage ? (
            <InputError className='onSubmitError'>{/* {(commentForMemberError || commentForHRError) && 'Есть незаполненные поля.'} */}</InputError>
          ) : (
            <InputError className='onSubmitError success'>{onSubmitSuccessMessage}</InputError>
          )}
        </div>
      </section>
    </>
  );
};
