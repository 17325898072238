import './AdminClientForm.scss';

import * as Yup from 'yup';

import { FieldArray, Form, Formik } from 'formik';
import { backendURL, debug } from '../../App';
import { isAfter, parseISO } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import InputError from '../../components/InputError';
import PageLoader from '../../components/PageLoader';
import Radiobutton from '../../components/Radiobutton/';
import TextareaWYSIWYG from '../../components/TextareaWYSIWYG';
import Title from '../../components/Title';
import _ from 'lodash';
import axios from 'axios';
import consoleLog from '../../helpers/consoleLog';
import icon_closeBlue from '../../assets/images/icon_closeBlue.svg';
import icon_plus from '../../assets/images/icon_plus.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientData from '../../hooks/useClientData';

export const AdminClientForm: React.FC<{}> = () => {
  const history = useHistory();
  const { clientID } = useParams<{ clientID: string }>();
  const { data: clientData, status: clientDataLoadingStatus } = useClientData(clientID, localStorage.getItem('skillsSurveyUserToken'), undefined);

  if (clientDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientForm' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Formik
        initialValues={{
          title: clientData?.title || '',
          short_title: clientData?.short_title || '',
          hex_color: clientData?.hex_color || '',
          logo: clientData?.logo || '',
          small_logo: clientData?.small_logo || '',
          uploadingLogo: {} as File,
          uploadingSmallLogo: {} as File,
          skype: clientData?.skype || '',
          email: clientData?.email || '',
          registration_open: clientData?.registration_open || true,
          report_one_stream: clientData?.report_one_stream || false,
          // * Роли
          roles: clientData?.roles?.length > 0 ? clientData.roles : [{ client_id: '', id: '', title: '' }],
          // * Трайбы
          tribes: clientData?.tribes?.length > 0 ? clientData.tribes : [{ client_id: '', id: '', title: '' }],
          // * Потоки
          streams:
            clientData?.streams?.length > 0
              ? clientData.streams
              : [{ client_id: '', id: '', title: '', key: '', link: '', members_count: '', deadline: '', writing_deadline: '' }],
          // * Настройки юзеров
          id_by: clientData?.id_by || 0,
          // * Настройки отчётов
          report_percents: !_.isNil(clientData?.report_percents) ? clientData?.report_percents : true,
          show_spiders: !_.isNil(clientData?.show_spiders) ? clientData?.show_spiders : false,
          show_cases: !_.isNil(clientData?.show_cases) ? clientData?.show_cases : true,
          default_html: !_.isNil(clientData?.default_html) ? clientData?.default_html : true,
          email_html: clientData?.email_html || '',
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('поле должно быть заполнено'),
          short_title: Yup.string().required('поле должно быть заполнено').max(12, 'максимальная длина 12 символов'),
          streams: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('поле должно быть заполнено'),
              key: Yup.string(),
              members_count: Yup.string().required('поле должно быть заполнено'),
              writing_deadline: Yup.string()
                .required('поле должно быть заполнено')
                .nullable()
                .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'данные должны быть в формате yyyy-mm-dd'),
              deadline: Yup.string()
                .required('поле должно быть заполнено')
                .nullable()
                .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'данные должны быть в формате yyyy-mm-dd')
                .test('isLess', 'Дедлайн должен быть позже дедлайна письменной части', function (value) {
                  // @ts-ignore
                  return isAfter(parseISO(value), parseISO(this?.options?.parent?.writing_deadline));
                }),
            })
          ),
          email_html: Yup.string().when('default_html', {
            is: false,
            then: Yup.string().trim().required('поле должно быть заполнено'),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          let clientFormData = new FormData();
          Object.entries(values).forEach(([key, value]) => {
            if (key === 'uploadingLogo' && value.name) {
              clientFormData.append('logo', value);
            } else if (key === 'uploadingSmallLogo' && value.name) {
              clientFormData.append('small_logo', value);
            } else if (key === 'tribes' || key === 'roles' || key === 'streams') {
              clientFormData.append(key, JSON.stringify(value));
            } else {
              clientFormData.append(key, value);
            }
          });

          try {
            const { data: clientData } = await axios({
              method: 'post',
              url: clientID ? `${backendURL}client/${clientID}/` : `${backendURL}clients/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                'Content-Type': 'multipart/form-data',
              },
              data: clientFormData,
            });

            if (clientID) {
              await queryCache.invalidateQueries(['clientData', clientID]);
              await queryCache.invalidateQueries(['streamsData', clientID]);
              await queryCache.invalidateQueries(['tribeData', clientID]);
            }

            setSubmitting(false);
            setStatus({ success: true });
            setTimeout(() => {
              setStatus({ success: '' });
              !clientID && history.push(`/admin/${clientData.id}/edit/`);
            }, 3000);
            debug && consoleLog('success', 'Client edit success', clientData);
          } catch (error) {
            setSubmitting(false);
            history.push('/500/');
            debug && consoleLog('error', 'Something gone wrong', error);
          }
        }}
      >
        {props => (
          <Form>
            <Title>Добавление клиента</Title>
            <div className='inputFieldsWrapper'>
              <Input
                name='title'
                type='text'
                size='medium'
                value={props.values.title}
                onChange={event => props.setFieldValue('title', event.target.value)}
                onBlur={() => props.setFieldTouched('title', true)}
                placeholderType='alwaysVisible'
                placeholder='Название компании или клиента'
                isDisabled={props.isSubmitting}
                isValid={!(props.errors.title && props.touched.title)}
                validationError={props.errors.title && props.touched.title ? props.errors.title : undefined}
                autoComplete='off'
              />
              <Input
                name='short_title'
                type='text'
                size='medium'
                value={props.values.short_title}
                onChange={event => props.setFieldValue('short_title', event.target.value)}
                onBlur={() => props.setFieldTouched('short_title', true)}
                placeholderType='alwaysVisible'
                placeholder='Короткое название компании или клиента'
                isDisabled={props.isSubmitting}
                isValid={!(props.errors.short_title && props.touched.short_title)}
                validationError={props.errors.short_title && props.touched.short_title ? props.errors.short_title : undefined}
                autoComplete='off'
              />
              <Input
                name='hex_color'
                type='text'
                size='small'
                value={props.values.hex_color}
                onChange={event => props.setFieldValue('hex_color', event.target.value)}
                onBlur={() => props.setFieldTouched('hex_color', true)}
                placeholderType='alwaysVisible'
                placeholder='Корпоративный цвет в палитре RGB, формат - 255, 255, 255'
                isDisabled={props.isSubmitting}
                isValid={!(props.errors.hex_color && props.touched.hex_color)}
                validationError={props.errors.hex_color && props.touched.hex_color ? props.errors.hex_color : undefined}
                autoComplete='off'
              />
              <hr />
              <div className='fileFieldContainer'>
                <div className='fileFieldInfo'>
                  {props.values.logo && !props.values.uploadingLogo.name && (
                    <a href={props.values.logo} target='_blank' rel='noopener noreferrer' className='link'>
                      {props.values.logo}
                    </a>
                  )}
                  {!props.values.logo && !props.values.uploadingLogo.name ? <span>Загрузите логотип клиента в формате svg</span> : props.values.uploadingLogo.name}
                </div>
                <input type='file' name='uploadingLogo' id='uploadingLogo' onChange={event => props.setFieldValue('uploadingLogo', event.target.files![0])} />
                <Button type='button' buttonType='button' width='230rem' size='small' color='black'>
                  <label htmlFor='uploadingLogo'>Загрузить файл</label>
                </Button>
              </div>
              {console.log('test', isAfter(parseISO('2021-10-10'), parseISO('2021-10-11')))}
              <div className='fileFieldContainer'>
                <div className='fileFieldInfo'>
                  {props.values.small_logo && !props.values.uploadingSmallLogo.name && (
                    <a href={props.values.small_logo} target='_blank' rel='noopener noreferrer' className='link'>
                      {props.values.small_logo}
                    </a>
                  )}
                  {!props.values.small_logo && !props.values.uploadingSmallLogo.name ? (
                    <span>
                      Загрузите маленький, скруглённый логотип <br /> клиента в формате svg
                    </span>
                  ) : (
                    props.values.uploadingSmallLogo.name
                  )}
                </div>
                <input
                  type='file'
                  name='uploadingSmallLogo'
                  id='uploadingSmallLogo'
                  onChange={event => props.setFieldValue('uploadingSmallLogo', event.target.files![0])}
                />
                <Button type='button' buttonType='button' width='230rem' size='small' color='black'>
                  <label htmlFor='uploadingSmallLogo'>Загрузить файл</label>
                </Button>
              </div>
              <hr />
              <Input
                name='skype'
                type='text'
                size='small'
                value={props.values.skype}
                onChange={event => props.setFieldValue('skype', event.target.value)}
                onBlur={() => props.setFieldTouched('skype', true)}
                placeholderType='alwaysVisible'
                placeholder='Никнейм для экспертов в Skype'
                isDisabled={props.isSubmitting}
                isValid={!(props.errors.skype && props.touched.skype)}
                validationError={props.errors.skype && props.touched.skype ? props.errors.skype : undefined}
                autoComplete='off'
              />
              <Input
                name='email'
                type='text'
                size='small'
                value={props.values.email}
                onChange={event => props.setFieldValue('email', event.target.value)}
                onBlur={() => props.setFieldTouched('email', true)}
                placeholderType='alwaysVisible'
                placeholder='E-mail для поддержки клиента'
                isDisabled={props.isSubmitting}
                isValid={!(props.errors.email && props.touched.email)}
                validationError={props.errors.email && props.touched.email ? props.errors.email : undefined}
                autoComplete='off'
              />

              <Checkbox
                name='registration_open'
                checked={props.values.registration_open}
                onClick={() => props.setFieldValue('registration_open', !props.values.registration_open)}
                onBlur={() => props.setFieldTouched('registration_open', true)}
              >
                <span className='segmentName'>Регистрация открыта</span>
              </Checkbox>
            </div>

            <Title>Роли</Title>
            <div className='tribesFieldsWrapper'>
              <FieldArray name='roles'>
                {({ push, remove }) => (
                  <>
                    {props.values.roles.map((role, index) => (
                      <>
                        <Input
                          name={`roles[${index}].title`}
                          type='text'
                          size='medium'
                          value={props.values.roles[index].title}
                          onChange={event => props.setFieldValue(`roles[${index}].title`, event.target.value)}
                          onBlur={() => props.setFieldTouched(`roles[${index}].title`, true)}
                          placeholderType='alwaysVisible'
                          placeholder='Название роли'
                          isDisabled={props.isSubmitting}
                          // @ts-ignore
                          isValid={!(props.errors?.roles?.[index]?.title && props.touched?.roles?.[index]?.title)}
                          // @ts-ignore
                          validationError={props.errors?.roles?.[index]?.title && props.touched?.roles?.[index]?.title ? props.errors?.roles?.[index]?.title : undefined}
                          autoComplete='off'
                        />
                        {!(props.values.roles.length === 1 && index === 0) && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='white' onClick={() => remove(index)} className='whiteButton'>
                            <img src={icon_closeBlue} alt='icon' />
                          </Button>
                        )}
                        {index + 1 === props.values.roles.length && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='blue' onClick={() => push({ title: '' })}>
                            <img src={icon_plus} alt='icon' />
                          </Button>
                        )}
                      </>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>

            <Title>Трайбы</Title>
            <div className='tribesFieldsWrapper'>
              <FieldArray name='tribes'>
                {({ push, remove }) => (
                  <>
                    {props.values.tribes.map((tribe, index) => (
                      <>
                        <Input
                          name={`tribes[${index}].title`}
                          type='text'
                          size='medium'
                          value={props.values.tribes[index].title}
                          onChange={event => props.setFieldValue(`tribes[${index}].title`, event.target.value)}
                          onBlur={() => props.setFieldTouched(`tribes[${index}].title`, true)}
                          placeholderType='alwaysVisible'
                          placeholder='Название трайба'
                          isDisabled={props.isSubmitting}
                          // @ts-ignore
                          isValid={!(props.errors?.tribes?.[index]?.title && props.touched?.tribes?.[index]?.title)}
                          // @ts-ignore
                          validationError={
                            props.errors?.tribes?.[index]?.title && props.touched?.tribes?.[index]?.title ? props.errors?.tribes?.[index]?.title : undefined
                          }
                          autoComplete='off'
                        />

                        {!(props.values.tribes.length === 1 && index === 0) && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='white' onClick={() => remove(index)} className='whiteButton'>
                            <img src={icon_closeBlue} alt='icon' />
                          </Button>
                        )}
                        {index + 1 === props.values.tribes.length && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='blue' onClick={() => push({ title: '' })}>
                            <img src={icon_plus} alt='icon' />
                          </Button>
                        )}
                      </>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>

            <Title>Потоки</Title>
            <div className='streamsFieldsWrapper'>
              <FieldArray name='streams'>
                {({ push, remove }) =>
                  props.values.streams.map((stream, index) => (
                    <>
                      <Input
                        name={`streams[${index}].title`}
                        type='text'
                        size='medium'
                        value={props.values.streams[index].title}
                        onChange={event => props.setFieldValue(`streams[${index}].title`, event.target.value)}
                        onBlur={() => props.setFieldTouched(`streams[${index}].title`, true)}
                        placeholderType='alwaysVisible'
                        placeholder='Название потока'
                        isDisabled={props.isSubmitting}
                        // @ts-ignore
                        isValid={!(props.errors?.streams?.[index]?.title && props.touched?.streams?.[index]?.title)}
                        // @ts-ignore
                        validationError={
                          props.errors?.streams?.[index]?.title && props.touched?.streams?.[index]?.title ? props.errors?.streams?.[index]?.title : undefined
                        }
                        autoComplete='off'
                      />
                      <Input
                        name={`streams[${index}].key`}
                        type='text'
                        size='medium'
                        value={props.values.streams[index].key}
                        onChange={event => props.setFieldValue(`streams[${index}].key`, event.target.value)}
                        onBlur={() => props.setFieldTouched(`streams[${index}].key`, true)}
                        placeholderType='alwaysVisible'
                        placeholder='Key потока'
                        isDisabled={false}
                        // @ts-ignore
                        isValid={!(props.errors?.streams?.[index]?.key && props.touched?.streams?.[index]?.key)}
                        // @ts-ignore
                        validationError={props.errors?.streams?.[index]?.key && props.touched?.streams?.[index]?.key ? props.errors?.streams?.[index]?.key : undefined}
                        autoComplete='off'
                      />
                      <Input
                        name={`streams[${index}].writing_deadline`}
                        type='text'
                        size='medium'
                        value={props.values.streams[index].writing_deadline}
                        onChange={event => props.setFieldValue(`streams[${index}].writing_deadline`, event.target.value)}
                        onBlur={() => props.setFieldTouched(`streams[${index}].writing_deadline`, true)}
                        placeholderType='alwaysVisible'
                        placeholder='Дедлайн письменный части'
                        isDisabled={props.isSubmitting}
                        // @ts-ignore
                        isValid={!(props.errors?.streams?.[index]?.writing_deadline && props.touched?.streams?.[index]?.writing_deadline)}
                        // @ts-ignore
                        validationError={
                          props.errors?.streams?.[index]?.writing_deadline && props.touched?.streams?.[index]?.writing_deadline
                            ? props.errors?.streams?.[index]?.writing_deadline
                            : undefined
                        }
                        autoComplete='off'
                      />
                      <Input
                        name={`streams[${index}].deadline`}
                        type='text'
                        size='medium'
                        value={props.values.streams[index].deadline}
                        onChange={event => props.setFieldValue(`streams[${index}].deadline`, event.target.value)}
                        onBlur={() => props.setFieldTouched(`streams[${index}].deadline`, true)}
                        placeholderType='alwaysVisible'
                        placeholder='Дедлайн устной части'
                        isDisabled={props.isSubmitting}
                        // @ts-ignore
                        isValid={!(props.errors?.streams?.[index]?.deadline && props.touched?.streams?.[index]?.deadline)}
                        // @ts-ignore
                        validationError={
                          props.errors?.streams?.[index]?.deadline && props.touched?.streams?.[index]?.deadline ? props.errors?.streams?.[index]?.deadline : undefined
                        }
                        autoComplete='off'
                      />

                      <Input
                        name={`streams[${index}].members_count`}
                        type='text'
                        size='medium'
                        value={props.values.streams[index].members_count}
                        onChange={event => props.setFieldValue(`streams[${index}].members_count`, event.target.value)}
                        onBlur={() => props.setFieldTouched(`streams[${index}].members_count`, true)}
                        placeholderType='alwaysVisible'
                        placeholder='Ожидаемое число участников'
                        isDisabled={props.isSubmitting}
                        // @ts-ignore
                        isValid={!(props.errors?.streams?.[index]?.members_count && props.touched?.streams?.[index]?.members_count)}
                        // @ts-ignore
                        validationError={
                          props.errors?.streams?.[index]?.members_count && props.touched?.streams?.[index]?.members_count
                            ? props.errors?.streams?.[index]?.members_count
                            : undefined
                        }
                        autoComplete='off'
                      />

                      {!(props.values.streams.length === 1 && index === 0) && (
                        <Button type='button' buttonType='button' width='50rem' size='medium' color='white' onClick={() => remove(index)} className='whiteButton'>
                          <img src={icon_closeBlue} alt='icon' className='iconRemove' />
                        </Button>
                      )}
                      {index + 1 === props.values.streams.length && (
                        <Button
                          type='button'
                          buttonType='button'
                          width='50rem'
                          size='medium'
                          color='blue'
                          onClick={() => push({ title: '', key: '', deadline: '', writing_deadline: '', members_count: '' })}
                        >
                          <img src={icon_plus} alt='icon' />
                        </Button>
                      )}
                    </>
                  ))
                }
              </FieldArray>
            </div>

            <Title>Настройки отчётов</Title>
            <div className='reportsFieldsWrapper'>
              <Radiobutton name='report_percents' checked={props.values.report_percents} value='true' onClick={() => props.setFieldValue('report_percents', true)}>
                <span className='key'>Результаты отчётов в процентах</span>
                <span className='value'>Если выбрана эта опция, в отчётах результаты будут оцениваться в процентах</span>
              </Radiobutton>
              <Radiobutton name='report_percents' checked={!props.values.report_percents} value='false' onClick={() => props.setFieldValue('report_percents', false)}>
                <span className='key'>Результаты отчётов в баллах</span>
                <span className='value'>Если выбрана эта опция, в отчётах результаты будут оцениваться в баллах</span>
              </Radiobutton>
              <Checkbox
                name='show_spiders'
                checked={props.values.show_spiders}
                onClick={() => props.setFieldValue('show_spiders', !props.values.show_spiders)}
                onBlur={() => props.setFieldTouched('show_spiders', true)}
              >
                <span className='key'>Spider Chart в отчётах участника</span>
                <span className='value'>
                  Если выбрана эта опция, в отчётах участника будут показываться <br /> Spider Chart's / графики компетенций
                </span>
              </Checkbox>
              <Checkbox
                name='report_one_stream'
                checked={!props.values.report_one_stream}
                onClick={() => props.setFieldValue('report_one_stream', !props.values.report_one_stream)}
                onBlur={() => props.setFieldTouched('report_one_stream', true)}
              >
                <span className='key'>Сравнительные отчёты</span>
                <span className='value'>Если выбрана эта опция, для потоков будут формироваться сравнительные отчёты </span>
              </Checkbox>
              <Checkbox
                name='show_cases'
                checked={props.values.show_cases}
                onClick={() => props.setFieldValue('show_cases', !props.values.show_cases)}
                onBlur={() => props.setFieldTouched('show_cases', true)}
              >
                <span className='key'>Идивидуальные результаты</span>
                <span className='value'>Если выбрана эта опция, индвидуальные результаты будут отображаться в групповых ( трайб / клиент ) отчётах </span>
              </Checkbox>
            </div>
            <Title>Письмо участникам</Title>
            <div className='emailsFieldsWrapper'>
              <Checkbox
                name='default_html'
                checked={props.values.default_html}
                onClick={() => props.setFieldValue('default_html', !props.values.default_html)}
                onBlur={() => props.setFieldTouched('default_html', true)}
              >
                <span className='key'>Стандартный текст</span>
                <span className='value'>Если выбрана эта опция, участникам будет присылаться стандартный текст письма</span>
              </Checkbox>
              <hr />
              <TextareaWYSIWYG
                value={props.values.email_html}
                onChange={value => props.setFieldValue('email_html', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('email_html', true)}
                placeholderType='alwaysVisible'
                placeholder='Кастомный текст письма'
                isValid={!(props.errors.email_html && props.touched.email_html)}
                validationError={props.errors.email_html && props.touched.email_html ? props.errors.email_html : undefined}
                modulesType='emailTemplating'
                isDisabled={props.values.default_html === true}
              />
            </div>

            <Button type='button' buttonType='submit' width='330rem' size='medium' color='blue' text='Готово' loading={props.isSubmitting} className='submitButton' />
            <InputError className={`${props.status?.success ? 'success' : ''}`}>
              {props.status?.success === true && 'Данные успешно сохранены'}
              {!_.isEmpty(props.errors) && 'Есть незаполненные поля'}
            </InputError>
          </Form>
        )}
      </Formik>
    </motion.main>
  );
};
